import { Auth } from '../auth';
import { rpcUrl } from '../constant';
import { AuthError, Base58String, Base64String } from '../types';

class SolanaService {
    constructor(private auth: Auth) {}

    private assertSolana() {
        if (this.auth.chain()?.name?.toLowerCase() !== 'solana') {
            throw AuthError.unsupportedMethod();
        }
    }

    public signAndSendTransaction(transaction: Base58String): Promise<Base64String> {
        this.assertSolana();
        return this.auth.sendTransaction(transaction);
    }

    public async signTransaction(transaction: Base58String): Promise<Uint8Array> {
        this.assertSolana();
        const tx = await this.auth.sign('signTransaction', transaction);
        return Buffer.from(tx, 'base64');
    }

    public async signAllTransactions(transactions: Base58String[]): Promise<Uint8Array[]> {
        this.assertSolana();
        const txs = await this.auth.signAllTransactions(transactions);
        return txs.map((tx) => Buffer.from(tx, 'base64'));
    }

    public async signMessage(msg: Base58String): Promise<Uint8Array> {
        this.assertSolana();
        const signature = await this.auth.sign('signMessage', msg);
        return Buffer.from(signature, 'base64');
    }

    public async getAddress(): Promise<Base58String | undefined> {
        return this.auth.getSolanaAddress();
    }

    public getRpcUrl(): string {
        this.assertSolana();
        const domain = `${rpcUrl()}/solana`;
        return `${domain}?chainId=${this.auth.chain().id}&projectUuid=${this.auth.config.projectId}&projectKey=${
            this.auth.config.clientKey
        }`;
    }
}

export default SolanaService;
