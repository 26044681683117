import { ActiveAction, BI } from '@particle-network/analytics';
import { Chain, chains } from '@particle-network/common';
import { Auth } from './auth';
import { WalletEntryPlugin, WalletEntryPosition } from './components/walletEntry/index';
import { apiUrl, authUrl } from './constant';
import { controller } from './controller';
import EvmService from './service/evmService';
import SolanaService from './service/solanaService';
import {
    AuthError,
    AuthThemeConfig,
    Config,
    CurrencyUnit,
    EVMProvider,
    OpenBuyOptions,
    SolanaWallet,
    Wallet,
} from './types';
import { getDeviceId, getVersion } from './utils';
import { particleActive } from './utils/active';
import { WalletConfig, getBuyUrl, getWalletUrl } from './utils/wallet-url';

declare global {
    interface Window {
        particle?: ParticleNetwork;
        __PARTICLE_ENVIRONMENT__?: string;
        __PARTICLE_AUTH_LOCALHOST__?: string;
        ethereum?: any;
        solana?: any;
    }
}

export class ParticleNetwork {
    public readonly isParticleNetwork = true;
    public readonly auth: Auth;
    private readonly config: Config;

    particleProvider: EVMProvider;

    localProvider: EVMProvider;

    solanaWallet: SolanaWallet;

    public readonly bi: BI;

    private walletEntry?: WalletEntryPlugin;

    public evm: EvmService;

    public solana: SolanaService;

    constructor(config?: Config) {
        if (!config) {
            //for solana wallet adapter
            config = {
                projectId: '34c6b829-5b89-44e8-90a9-6d982787b9c9',
                clientKey: 'c6Z44Ml4TQeNhctvwYgdSv6DBzfjf6t6CB0JDscR',
                appId: 'c1ad1496-5707-4db6-8a2b-3e9f7273d846',
                chainName: 'Solana',
                chainId: 101, //mainnet
                preload: true,
                wallet: {
                    displayWalletEntry: true,
                    defaultWalletEntryPosition: WalletEntryPosition.BR,
                },
            };
        }
        if (
            (!config.chainName || typeof config.chainName === 'string') &&
            (!config.chainId || typeof config.chainId === 'number') &&
            typeof config.projectId === 'string' &&
            typeof config.clientKey === 'string' &&
            typeof config.appId === 'string'
        ) {
            if (config.chainName && config.chainId) {
                const chain = chains.getChainInfo({
                    id: config.chainId,
                    name: config.chainName,
                });
                if (!chain) {
                    throw AuthError.unsupportedChain();
                }
            } else {
                config.chainName = 'Ethereum';
                config.chainId = 1;
            }

            this.config = config;

            this.bi = new BI({
                sdk_api_domain: apiUrl(),
                device_id: getDeviceId(),
                sdk_version: getVersion(),
                project_config: {
                    project_uuid: config.projectId,
                    project_key: config.clientKey,
                    project_app_uuid: config.appId,
                },
            });

            this.auth = new Auth(this.config, this.bi);

            // create Wallet Entry
            if (this.config?.wallet?.displayWalletEntry === undefined || this.config?.wallet?.displayWalletEntry) {
                this.walletEntryCreate();
            }

            this.evm = new EvmService(this.auth);
            this.solana = new SolanaService(this.auth);

            if (typeof window !== 'undefined') {
                this.openActive();
                window.particle = this;
            }
        } else {
            throw AuthError.paramsError();
        }
        if (config?.preload !== false && typeof window !== 'undefined') {
            setTimeout(() => {
                this.preload();
            }, 2000);
        }
    }

    private preload() {
        const url = authUrl();

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';

        iframe.src = url + '?preload=true';

        iframe.addEventListener('load', () => {
            setTimeout(() => {
                iframe?.parentNode?.removeChild?.(iframe);
            }, 3000);
        });

        document.body.appendChild(iframe);
    }

    private openActive() {
        if (this.auth.isLogin() && this.auth.wallet()?.public_address) {
            particleActive(
                this.bi,
                this.auth.chainId(),
                this.auth.wallet()!.public_address,
                this.auth.userInfo()!,
                ActiveAction.OPEN
            );
        }
    }

    public setAuthTheme(config: AuthThemeConfig) {
        this.auth.setAuthTheme(config);
    }

    public getAuthTheme() {
        return this.auth.getAuthTheme();
    }

    public async switchChain(chain: Chain, hideLoading = false): Promise<Wallet[]> {
        return this.auth.switchChain(chain, hideLoading);
    }

    public setChainInfo(chain: Chain) {
        this.auth.setChainInfo(chain);
    }

    // wallet destroy
    public walletEntryDestroy() {
        this.walletEntry?.destroy();
        this.walletEntry = undefined;
    }

    // create wallet entry
    public walletEntryCreate() {
        this.walletEntry = new WalletEntryPlugin(this.auth, {
            position: this.config.wallet?.defaultWalletEntryPosition || WalletEntryPosition.BR,
            preload: this.config.wallet?.preload,
        });
    }

    /**
     * open wallet web wallet
     * @param target window open target
     * @param features window open features
     */
    public openWallet(target?: string, features?: string) {
        const url = getWalletUrl(this.auth);
        if (typeof window !== 'undefined') {
            if (this.auth.isLogin() && this.auth.wallet()?.public_address) {
                particleActive(
                    this.bi,
                    this.auth.chainId(),
                    this.auth.wallet()!.public_address,
                    this.auth.userInfo()!,
                    ActiveAction.OPEN_WALLET
                );
            }
            window.open(url, target, features);
        }
    }

    public buildWalletUrl(config?: WalletConfig) {
        const url = getWalletUrl(this.auth, config);
        return url;
    }

    public openBuy(options?: OpenBuyOptions, target?: string, features?: string) {
        const url = getBuyUrl(this.auth, options);
        if (typeof window !== 'undefined') {
            window.open(url, target, features);
        }
    }

    public setLanguage(code: string) {
        controller.languageCode = code;
    }

    public getLanguage(): string {
        return controller.languageCode;
    }

    get version() {
        return getVersion();
    }

    public setFiatCoin(unit: CurrencyUnit) {
        controller.fiatCoin = unit;
    }

    public getChain(): Chain {
        return this.auth.chain();
    }

    /**
     * ERC-4337: Account Abstraction Using Alt Mempool
     * @param enable Whether to enable the 4337 mode
     * @see https://eips.ethereum.org/EIPS/eip-4337
     */
    public setERC4337(enable: boolean) {
        controller.erc4337 = enable;
    }
}
